exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blogs-best-microsoft-teams-apps-tsx": () => import("./../../../src/pages/blogs/best-microsoft-teams-apps.tsx" /* webpackChunkName: "component---src-pages-blogs-best-microsoft-teams-apps-tsx" */),
  "component---src-pages-blogs-career-goals-tsx": () => import("./../../../src/pages/blogs/career-goals.tsx" /* webpackChunkName: "component---src-pages-blogs-career-goals-tsx" */),
  "component---src-pages-blogs-cross-team-collaboration-tsx": () => import("./../../../src/pages/blogs/cross-team-collaboration.tsx" /* webpackChunkName: "component---src-pages-blogs-cross-team-collaboration-tsx" */),
  "component---src-pages-blogs-employee-coaching-tsx": () => import("./../../../src/pages/blogs/employee-coaching.tsx" /* webpackChunkName: "component---src-pages-blogs-employee-coaching-tsx" */),
  "component---src-pages-blogs-employee-corporate-ladder-tsx": () => import("./../../../src/pages/blogs/employee-corporate-ladder.tsx" /* webpackChunkName: "component---src-pages-blogs-employee-corporate-ladder-tsx" */),
  "component---src-pages-blogs-employee-development-tsx": () => import("./../../../src/pages/blogs/employee-development.tsx" /* webpackChunkName: "component---src-pages-blogs-employee-development-tsx" */),
  "component---src-pages-blogs-employee-engagement-tsx": () => import("./../../../src/pages/blogs/employee-engagement.tsx" /* webpackChunkName: "component---src-pages-blogs-employee-engagement-tsx" */),
  "component---src-pages-blogs-employee-productivity-burnout-tsx": () => import("./../../../src/pages/blogs/employee-productivity-burnout.tsx" /* webpackChunkName: "component---src-pages-blogs-employee-productivity-burnout-tsx" */),
  "component---src-pages-blogs-employee-retention-software-tsx": () => import("./../../../src/pages/blogs/employee-retention-software.tsx" /* webpackChunkName: "component---src-pages-blogs-employee-retention-software-tsx" */),
  "component---src-pages-blogs-employee-retention-tsx": () => import("./../../../src/pages/blogs/employee-retention.tsx" /* webpackChunkName: "component---src-pages-blogs-employee-retention-tsx" */),
  "component---src-pages-blogs-employee-skills-development-tsx": () => import("./../../../src/pages/blogs/employee-skills-development.tsx" /* webpackChunkName: "component---src-pages-blogs-employee-skills-development-tsx" */),
  "component---src-pages-blogs-employee-skills-evaluation-tsx": () => import("./../../../src/pages/blogs/employee-skills-evaluation.tsx" /* webpackChunkName: "component---src-pages-blogs-employee-skills-evaluation-tsx" */),
  "component---src-pages-blogs-index-tsx": () => import("./../../../src/pages/blogs/index.tsx" /* webpackChunkName: "component---src-pages-blogs-index-tsx" */),
  "component---src-pages-blogs-mentorship-company-tsx": () => import("./../../../src/pages/blogs/mentorship-company.tsx" /* webpackChunkName: "component---src-pages-blogs-mentorship-company-tsx" */),
  "component---src-pages-blogs-quiet-quitting-tsx": () => import("./../../../src/pages/blogs/quiet-quitting.tsx" /* webpackChunkName: "component---src-pages-blogs-quiet-quitting-tsx" */),
  "component---src-pages-blogs-talent-architecture-tsx": () => import("./../../../src/pages/blogs/talent-architecture.tsx" /* webpackChunkName: "component---src-pages-blogs-talent-architecture-tsx" */),
  "component---src-pages-blogs-talent-management-guide-tsx": () => import("./../../../src/pages/blogs/talent-management-guide.tsx" /* webpackChunkName: "component---src-pages-blogs-talent-management-guide-tsx" */),
  "component---src-pages-blogs-upskilling-tsx": () => import("./../../../src/pages/blogs/upskilling.tsx" /* webpackChunkName: "component---src-pages-blogs-upskilling-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-solutions-connect-tech-professionals-tsx": () => import("./../../../src/pages/solutions/connect-tech-professionals.tsx" /* webpackChunkName: "component---src-pages-solutions-connect-tech-professionals-tsx" */),
  "component---src-pages-solutions-cross-team-collaboration-tsx": () => import("./../../../src/pages/solutions/cross-team-collaboration.tsx" /* webpackChunkName: "component---src-pages-solutions-cross-team-collaboration-tsx" */),
  "component---src-pages-solutions-employee-engagement-tsx": () => import("./../../../src/pages/solutions/employee-engagement.tsx" /* webpackChunkName: "component---src-pages-solutions-employee-engagement-tsx" */),
  "component---src-pages-solutions-hybrid-team-collaboration-tsx": () => import("./../../../src/pages/solutions/hybrid-team-collaboration.tsx" /* webpackChunkName: "component---src-pages-solutions-hybrid-team-collaboration-tsx" */),
  "component---src-pages-solutions-index-tsx": () => import("./../../../src/pages/solutions/index.tsx" /* webpackChunkName: "component---src-pages-solutions-index-tsx" */),
  "component---src-pages-solutions-resource-allocation-tsx": () => import("./../../../src/pages/solutions/resource-allocation.tsx" /* webpackChunkName: "component---src-pages-solutions-resource-allocation-tsx" */)
}

